import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="h1" components={components}>{`The Difference Between Well-Written & Worth-Reading`}</MDXTag>
      <MDXTag name="p" components={components}>{`I'll admit, I got carried away. Walking away from `}<MDXTag name="inlineCode" components={components} parentName="p">{`quixote.dev`}</MDXTag>{` felt like a betrayal of a younger, more cultured me, but I didn’t bite. Instead I bought `}<MDXTag name="inlineCode" components={components} parentName="p">{`jeeves.dev`}</MDXTag>{` because I wanted to put `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "mailto:ask@jeeves.dev"
        }}>{`ask@jeeves.dev`}</MDXTag>{` on resumes and because I who knows, maybe that's what I go by from now on.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Out of respect for the Knight of the Sorrowful Face, I reread the introduction, in which Don Quixote is driven to mad by overexposure to the high language of knight errantry.`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`“Finally, from so little sleeping and so much reading, his brain dried up and he went completely out of his mind.”`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`This line was always one of my favorites. In my experience the test of a good book is not just whether I liked it, but whether it gives me something new when I pick it back up.`}</MDXTag>
      <MDXTag name="p" components={components}>{`I remember where I was when I read that line. Books do that to me like movies never have.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Today I was reminded me of Tommy, an old friend. Along with being a generally quixotic dude, he also helped design a video arcade cabinet called `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "http://www.lazaknitez.com/"
        }}>{`LAZA KNITEZ`}</MDXTag>{` — starring rogues the likes of Lord Lanzalot the Lewd, Duke Dwayne the Dastardly, and Earl Earl the Early Earl — Tommy was the loud, fun, cocky SOB that you’d picture coming up out of the Bayou, but unexpectedly and without a shade of irony in his voice he would drop a line from King Lear, like he’d been a fucking general or alive in the 70s or something. We all fell in love with him immediately, of course.`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`Chivalry isn’t dead — it’s just respawning
— Duke Dwayne the Dastardly`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`All that to say, I picked up reading again, bought a couple literary .dev domains, and while part of me was overjoyed when I saw my old friends again – Fyodor Karamazov the buffoon, El Caballero de la Triste Figura, Ishmael and all the ones I’ve forgotten since I since my books — what I mostly felt was this lowkey sense of my burned out self.`}</MDXTag>
      <MDXTag name="p" components={components}>{`So I pulled out the bankers box whose stationary perch atop my desk was once a poison to me, the slow sick poisoning of my own literary inertia. I avoided that box like I would a conversation with a stranger. Or worse, an obsequious acquaintance that you dislike but feel bad about disliking, he’s nice and besides personality is just a construct, an interface we create between ourselves and our environment, right? so you try to smile, say his name and look him in the eye, and so forth, but suddenly you feel every bit as obsequious and subject to your environment as he is. Next time I’ll be colder. More honest. Honesty, I owe him that. Proximity alone is not sufficient cause for friendship.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Anyway I opened the box and it’s such a drag. Everything hurts, especially the passages I remembered and was excited to read — my anticipation was salt in the would. I felt foolish.`}</MDXTag>
      <MDXTag name="p" components={components}>{`I also realized something about younger, “writerly” self. All of my prose aspired to be `}<MDXTag name="em" components={components} parentName="p">{`well-written`}</MDXTag>{`; even had I achieved that, I never asked if what I was writing was `}<MDXTag name="strong" components={components} parentName="p">{`worth reading`}</MDXTag>{`. In fact, I took it for granted.`}</MDXTag>
      <MDXTag name="p" components={components}>{`What was the point of all of this? `}<MDXTag name="em" components={components} parentName="p">{`Why`}</MDXTag>{` had I written so much, so often?`}</MDXTag>
      <MDXTag name="p" components={components}>{`If I’m being honest with myself, it was all about my ego.`}</MDXTag>
      <MDXTag name="p" components={components}>{`What astounds me is my own capacity for self-deception. I used to fantacising about younger writers one day looking up to me, poring over my words the way I did my heroes `}<MDXTag name="em" components={components} parentName="p">{`while`}</MDXTag>{` talking to dates in coffeeshops about how I didn’t really like to talk about it becaue it seemed pretentious.`}</MDXTag>
      <MDXTag name="p" components={components}>{`It `}<MDXTag name="em" components={components} parentName="p">{`was`}</MDXTag>{` pretentious.`}</MDXTag>
      <MDXTag name="p" components={components}>{`To the extent that I was posturing myself, it was pretentious. My love for reading, and fiction in particular, has been a defining theme in my life. Books have ruined me and books have made me. But when I discovered in college that loving books was an attractive quality, I sold out and convinced myself I hadn’t by doubling down, reading more pages, carrying a pen with me at all times, in case a moment of inspiration struck (or a pretty girl was nearby)?`}</MDXTag>
      <MDXTag name="p" components={components}>{`I supposed it’s easy to second guess myself. Maybe my intentions were pure, or at least I thought they were.`}</MDXTag>
      <MDXTag name="p" components={components}>{`If you’re like me, you can be too hard on yourself. Maybe this is that. Or maybe that’s an excuse and learning how to tell the truth again is painful. Maybe the next lesson will be knowing the difference between a time to talk publicly and a time to shut the hell up.`}</MDXTag>
      <MDXTag name="p" components={components}>{`But this one seems like a lesson worth learning, if only to say make up for all the times I sung these words like I knew what they meant:`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`I need three years to clear these thoughts, hey
I like to say I knew one true thing
It feels like years and all I've done is fought
And not turned up anything
`}<MDXTag name="em" components={components} parentName="p">{`Rain King`}</MDXTag>{`, Sonic Youth`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`And so, for all the lip service I just paid to telling the truth, I would be remiss if I didn’t say that some small part of me isn’t secretly happy with how this part has aged.`}</MDXTag>
      <MDXTag name="p" components={components}>{`And if I could do it over again, I would tell myself to find a mentor. A mentor who, at times, treats me like a peer, so that our rapport has some basis; a sharp word from a drill instructor stings in a very different way than the rebuke of a peer. Find someone who cares enough about the craft that they find carelessness, offensive — even hurtful.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Then again, it’s easy to become too familiar with a mentor, to the point that you both become complacent friends. It is always much more difficult to reestablish boundaries than to maintain them.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Some would argue that this is the natural way of things; formal roles always dissolve eventually, relationships are dynamic and like all things, tend toward entropy. I agree.`}</MDXTag>
      <MDXTag name="p" components={components}>{`For me — and maybe just me, although I suspect not — this is all the more reason to find and fight to keep a mentor. Someone you care about, such that you become a member of their support system, but not your emergency contact.`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`Just a half-eaten sandwich and some soggy napkins.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Anyway, that’s enough from me. I am not going to revise this much; I doubt revision would really be the difference between well-written and worth-reading for this post.`}</MDXTag>
      <MDXTag name="p" components={components}>{`So yes, I’m disappointed. Disappointed that I didn’t trip over my own genius. Everyone wants to discover the unwitting brilliance of her former self in a banker box, or a forgotten Dropbox account somewhere.`}</MDXTag>
      <MDXTag name="p" components={components}>{`For today, I find myself underwhelmed once again by the sheer magnitide of my effort — years, pages, etc. — when compared to the few examples I can produce that are `}<MDXTag name="strong" components={components} parentName="p">{`eloquent, but also useful`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`And as she slid deeper into her bench, her final thoughts before drifting off were questions she didn't try to answer: where the words had come from and why they’d chosen her, where the tycoon vacationed, if cigars tasted like they smelled and whether God was in it, whether she owed him for the words, whether she might think to write them down so she doesn’t forget the Campbell Soup Company’s new slogan between now and her meeting with Mr. Kirkland at eight.
Four words that came from nowhere. She dreamed of a stained glass window and rain that fell in angles.`}</MDXTag>
        <MDXTag name="p" components={components} parentName="blockquote">{`From `}<MDXTag name="em" components={components} parentName="p">{`Soup Is Good Food`}</MDXTag>{`, 2012`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Still pretty cringy, especially that meter at the end — I think I was reading Bolaño at the time. Still, there’s enough going on that I’d like to see it through.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Today I bought a bunch of Google dev domains.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Tomorrow I will stay home and play in the AWS console all day until I finally understand how DNS resolves a request.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Or until think of something funny to do with these `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": ""
        }}>{`jeeves.dev`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`My name’s Andrew, I’d say you should hire me but if you’re really looking for an engineer I probably lost you at Duke Dwayne the Dastardly.`}</MDXTag>
      <hr />
      <MDXTag name="p" components={components}>{`In the off chance that you have something constructive or humorous to say, feel free to ask@jeeves.dev — only give it a week or two, I haven’t gotten around to configuring the name servers yet.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Thanks for reading!`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{
          "src": "/images/2019-03-05-numerology-in-bangkok.jpg",
          "alt": "The Writing on the Wall: Numerology in the Streets of Bangkok, Thailand"
        }}></MDXTag></MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`Image: BANGKOK - MAY 27: View of numerology written on a wall in the city centre on May 27, 2013 in Bangkok, Thailand. It is commonplace to see numerology written in public areas in the Thai capital.`}</MDXTag>
      </MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "path": "/2010-03-05-difference-between-well-written-and-worth-reading",
  "date": "2010-03-05",
  "title": "The Difference Between Well-Written & Worth Reading",
  "image": "2010-03-05-numerology-in-bangkok.jpg",
  "sharpImage": true,
  "imgOverlay": null,
  "tags": ["writing", "books", "literature"],
  "excerpt": "All the Google dev domain buzz annoyed me until I saw ishmael.dev & ahab.dev for sale for $12. Unfortunately borges.dev was taken, but quixote.dev & karamazov.dev were tempting. Presently that got me thinking about...",
  "published": false
};
      